@import "../assets/scss/variables";

.favDiv {
  .imgDiv {
    height: 566px;
    width: 100%;
    background-color: $gray;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 2rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      transform: translateX(50%);
      transition: ease all 0.5s;
    }

    &:hover {
      img {
        transform: translateX(0%);
      }
    }
  }

  .infoDiv {
    text-align: left;
    margin-bottom: 1rem;

    h5 {
      font-size: 24px;
      margin: 0 0 0.5rem;
    }

    p {
      font-size: 14px;
      color: $gray-3;
    }
  }

  .soon {
    color: #ff2f2f;
  }

  &.airtags {
    .imgDiv {
      img {
        width: 125%;
        transform: translateX(40%);
        transition: ease all 0.5s;
      }

      &:hover {
        img {
          transform: translateX(-9%);
        }
      }
    }
  }
}

@media screen and (max-width: 499px) {
  .favDiv {
    margin-bottom: 1rem;

    .imgDiv {
      img {
        transform: none;
      }
    }

    &.airtags {
      .imgDiv {
        img {
          width: 100%;
          transform: none;
        }
      }
    }
  }
}
