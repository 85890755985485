@font-face {
  font-family: "Yellix";
  src: local("DroidKufi"), url(../fonts/Yellix-Medium.woff2) format("woff2"),
    url(../fonts/Yellix-Medium.woff) format("woff");
}

@font-face {
  font-family: "Yellix";
  src: local("Yellix"), url(../fonts/Yellix-Bold.woff2) format("woff2"),
    url(../fonts/Yellix-Bold.woff) format("woff");
  font-weight: bold;
}

@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
@import "bootstrap.scss";
@import "variables.scss";
@import "fonts.scss";
@import "custom.scss";
