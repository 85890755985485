@import "../assets/scss/variables";

.header {
  padding: 2rem 3rem;
  position: relative;
  z-index: 999;
}

.logoDiv {
  width: 112px;

  img {
    width: 100%;
  }
}

.mainNav {
  > ul {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
      list-style: none;
      display: inline-block;
      font-size: 0;
      position: relative;

      &:nth-of-type(2) {
        margin: 0 0 0 4rem;
      }

      a {
        display: inline-block;
        color: $black;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        transition: all ease 0.3s;
        text-shadow: -0.25px -0.25px 0 transparent, 0.25px 0.25px transparent;
        margin: 0 4px;

        &:hover {
          text-decoration: none;
          text-shadow: -0.25px -0.25px 0 $black, 0.25px 0.25px $black;
        }

        &.active {
          font-weight: bold;
        }
      }

      &:hover {
        ul.subLinks {
          display: block;
        }
      }

      ul.subLinks {
        display: none;
        position: absolute;
        padding: 0;
        margin: 0;
        list-style: none;
        top: 1.5rem;
        left: -35px;
        padding-top: 1.5rem;

        li {
          display: block;
          padding: 12px;
          text-align: center;
          width: 140px;
          margin: 0 0 4px;
          border-bottom: 1px solid transparent;

          a {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            opacity: 0.6;

            &::after {
              display: none;
            }
          }

          &:hover {
            a {
              opacity: 1;
            }

            border-color: #000;
          }
        }
      }
    }
  }

  &.white {
    ul {
      li {
        a {
          color: $white;
        }

        ul.subLinks {
          li {
            a {
              color: #fff !important;
            }

            &:hover {
              border-color: #fff !important;
            }
          }
        }
      }
    }
  }
}

.searchDiv,
.wishlistDiv,
.cartDiv {
  color: $black;

  a {
    color: $black;

    &:hover {
      text-decoration: none;
      color: $black;
    }
  }

  &.white {
    color: $white;

    a {
      color: $white;

      &:hover {
        text-decoration: none;
        color: $white;
      }
    }
  }
}

.searchDiv {
  margin-right: 2rem;
}

.cartDiv {
  img {
    width: 22px;
    position: relative;
    top: -2px;
  }
}

.menuButton {
  outline: 0;
  margin: 0;
  border: 0;

  div {
    width: 18px;
    height: 2px;
    background-color: #000;
    transition: 0.4s ease all;

    &:first-of-type {
      margin-bottom: 8px;
    }
  }

  &.opened {
    div {
      transform: rotate(45deg);
      position: relative;
      top: 5px;

      &:last-of-type {
        transform: rotate(-45deg);
        top: -5px;
      }
    }
  }
}

.mobileMenu {
  position: fixed;
  top: 60px;
  left: 0;
  background-color: #f2f2f4;
  width: 100%;
  min-height: 100vh;
  padding: 1rem;

  .searchDiv {
    margin-right: 0;
  }

  .mainNav {
    > ul {
      > li {
        display: block;
        margin-bottom: 1rem !important;

        &:nth-of-type(2) {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        a {
          font-size: 33px;
          line-height: 40px;
          text-align: left;
          font-weight: bold;
          text-align: left;
        }

        ul.subLinks {
          position: relative;
          top: 0;
          left: 0;
          padding-top: 0;
          display: block;
          margin-top: 0.5rem;

          li {
            display: block;
            padding: 1rem 0;
            text-align: left;
            width: 100%;
            margin: 0 0 4px;
            border-top: 1px solid #dbdbdb;
            border-bottom: 1px solid #dbdbdb;

            &:last-of-type {
              border-top: 1px solid transparent;
            }

            a {
              font-weight: 500;
              font-size: 23px;
              line-height: 28px;
              opacity: 1;
            }
          }
        }
      }
    }

    &.white {
      ul {
        li {
          a {
            color: $white;
          }

          ul.subLinks {
            li {
              a {
                color: #fff !important;
              }

              &:hover {
                border-color: #fff !important;
              }
            }
          }
        }
      }
    }
  }

  .socialDiv {
    margin-top: 2rem;
    text-align: center;
  }
}

@media screen and (max-width: 799px) {
  .header {
    padding: 1rem;

    .colMobile {
      justify-content: center !important;
      margin: 0 0 1rem;
    }
  }
}

@media screen and (max-width: 499px) {
  .header {
    padding: 1rem 0;
    background-color: #fff;
    box-shadow: 10px 10px 15px rgba(174, 174, 192, 0.15);

    .mainNav {
      ul {
        li {
          &:nth-of-type(2) {
            margin: 0 1rem;
          }
        }
      }
    }
  }
}
