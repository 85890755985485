$black: #000;
$white: #fff;
$gray: #f2f2f4;
$gray-2: #b9b9b9;
$gray-3: #828282;
$pink: #f7cccc;
$orange: #ffc648;
$brown: #673e20;
$red: #e14242;
$gray-4: #b1bdc2;
$blue: #2f80ed;
