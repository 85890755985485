@import "../../assets/scss/variables";

.mainSlider {
  position: relative;
  width: 100%;
  height: 100vh;
  top: -96px;
  left: 0;
  margin-bottom: -96px;

  .sliderDiv {
    background-color: $gray;
    padding: 0 4rem;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;

    > div {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }

    &.div2 {
      background-color: $black;

      .big {
        color: $white;
      }
    }

    &.div3 {
      background-color: $pink;

      .normal {
        color: $white;
      }
    }

    &.div4 {
      background-color: $orange;

      .normal {
        color: $black;
      }
    }

    .leftDiv {
      position: absolute;
      left: 4rem;
      top: 50%;
      transform: translateY(-50%);
      height: 300px;

      .normal {
        margin-bottom: 1.5rem;
      }
    }

    .centertDiv {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 100%;
        max-width: 500px;
      }

      .normal {
        display: none;
      }

      .big {
        display: none;
      }
    }

    .rightDiv {
      height: 300px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 4rem;
      display: flex;
      justify-content: flex-end;
      text-align: right;
    }

    .normal {
      font-size: 24px;
      line-height: 30px;
      color: $gray-3;
    }

    .big {
      font-size: 44px;
      line-height: 160%;
      color: $black;
    }
  }

  .socialDiv {
    position: absolute;
    bottom: 40px;
    left: 4rem;

    &.white {
      img {
        fill: #fff;
      }
    }
  }

  .button {
    position: absolute;
    bottom: 40px;
    right: 4rem;

    &.white {
      color: $white;
    }
  }

  .buttonMobile {
    display: none;
    position: absolute;
    bottom: 40px;
    right: 4rem;

    &.white {
      color: $white;
    }
  }
}

// .secondSlider {
//   position: relative;

//   .sliderDiv {
//     padding: 0 4rem;
//     display: flex;
//     flex-wrap: wrap;
//     align-content: center;
//     justify-content: space-between;
//     height: 600px;

//     .leftDiv {
//       position: absolute;
//       left: 4rem;
//       top: 50%;
//       transform: translateY(-50%);
//       height: 350px;
//       z-index: 99;

//       .normal {
//         margin-bottom: 1.5rem;
//       }
//     }

//     .centerDiv {
//       position: absolute;
//       left: 50%;
//       top: 50%;
//       transform: translate(-50%, -50%);
//       width: 860px;
//       height: 350px;
//       background-color: $gray;
//     }

//     .rightDiv {
//       position: absolute;
//       top: 50%;
//       transform: translateY(-50%);
//       right: 6rem;
//       display: flex;
//       justify-content: flex-end;
//       text-align: right;
//       height: 600px;

//       .icon {
//         position: absolute;
//         top: 126px;
//         right: -2rem;
//       }

//       img.mainImg {
//         width: 100%;
//         max-width: 600px;
//       }
//     }
//   }

//   .normal {
//     font-size: 24px;
//     line-height: 30px;
//     color: $gray-3;
//   }

//   .big {
//     font-size: 44px;
//     line-height: 160%;
//     font-weight: bold;
//     color: $black;
//   }
// }

.topBannerSection {
  margin: 0;
  width: 100%;
  position: relative;
  margin-bottom: 1rem;

  img {
    width: 100%;
  }
}

.favoritesSection {
  margin: 0 0 5rem;
  padding: 0 3rem;
}

.bannerSection {
  margin: 0 0 5rem;
  background-color: $pink;
  width: 100%;
  height: 600px;
  position: relative;

  .bannerImg {
    position: absolute;
    width: 100%;
    max-width: 1440px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .button {
    position: absolute;
    left: 50%;
    transform: translateX(-620px);
    bottom: 25%;
  }
}

.followSection {
  margin: 0 0 4rem;
  padding: 0 4rem 1rem;
}

.productsSection {
  position: relative;

  .sliderWrapper {
    background-color: $gray;
    padding: 4rem 4rem 8rem;
    position: relative;

    .arrowLeft,
    .arrowRight {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 2rem;
    }

    .arrowLeft {
      transform: translateY(-50%) rotate(180deg);
    }

    .arrowRight {
      left: auto;
      right: 2rem;
    }

    .sliderDiv {
      cursor: pointer;

      .centerDiv {
        padding: 0 2.5rem;

        h5 {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 128px;
        }

        .imgDiv {
          padding: 2.5rem;
          position: relative;
          transition: all ease 0.3s;

          img {
            width: 100%;
            transition: all ease 0.3s;
          }

          .button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: none;
            transition: all ease 0.3s;

            img {
              width: auto;
              filter: blur(0);
              transition: all ease 0.3s;
            }
          }
        }
      }

      &:hover {
        .centerDiv {
          .imgDiv {
            img {
              opacity: 0.5;
              filter: blur(4px);
            }

            .button {
              display: block;

              img {
                width: auto;
                opacity: 1;
                filter: blur(0);
              }
            }
          }
        }
      }
    }

    .button {
      position: absolute;
      bottom: 3rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.bannerSection2 {
  margin: 0;
  background-color: $gray-2;
  width: 100%;
  height: 600px;
  position: relative;

  .bannerImg {
    position: absolute;
    width: 100%;
    max-width: 1440px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 35%;
  }
}

@media screen and (max-width: 1439px) {
  .bannerSection {
    .button {
      position: absolute;
      left: 7%;
      transform: none;
      bottom: 18%;
    }
  }

  .bannerSection2 {
    height: 500px;

    .button {
      top: 38%;
    }
  }
}

@media screen and (max-width: 1023px) {
  .mainSlider {
    .sliderDiv {
      .normal {
        font-size: 16px;
        line-height: 160%;
      }

      .big {
        font-size: 24px;
        line-height: 160%;
      }
    }
  }

  .secondSlider {
    margin: 0 0 3rem;

    .sliderDiv {
      .leftDiv {
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        width: 100%;

        .icon {
          left: 3rem;
          position: absolute;
          top: 4px;
        }
      }

      .centerDiv {
        width: 100%;
        height: 350px;
      }

      .rightDiv {
        width: 100%;
        max-width: 100%;
        top: 180px;
        right: 0;
        text-align: center;
        justify-content: center;
        transform: none;

        .icon {
          top: -176px;
          right: 3rem;
        }
      }
    }
  }

  .favoritesSection {
    h1 {
      text-align: center;
    }

    > div {
      > div {
        > div {
          margin: 0 0 2rem;
        }
      }
    }
  }

  .bannerSection {
    height: 400px;
  }

  .productsSection {
    .sliderWrapper {
      .sliderDiv {
        .centerDiv {
          h5 {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 64px;
          }
        }

        &:hover {
          .centerDiv {
            .imgDiv {
              background-color: transparent;

              .button {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .bannerSection2 {
    height: 500px;

    .button {
      top: 5%;
    }
  }
}

@media screen and (max-width: 799px) {
  .mainSlider {
    height: 800px;
    top: -163px;
    margin-bottom: -163px;

    .sliderDiv {
      height: 800px;
      padding: 0 1rem;

      .leftDiv {
        left: 1rem;
      }

      .rightDiv {
        right: 1rem;
      }
    }

    .socialDiv {
      left: 1rem;
    }

    .button {
      right: 1rem;
    }
  }

  .secondSlider {
    margin: 0 0 3rem;

    .sliderDiv {
      padding: 0 1rem;

      .leftDiv {
        .icon {
          left: 1rem;
        }
      }

      .centerDiv {
        width: 100%;
        height: 350px;
      }

      .rightDiv {
        height: auto;

        .icon {
          right: 1rem;
        }
      }
    }
  }

  .favoritesSection {
    padding: 0;

    > div {
      > div {
        > div {
          margin: 0 0 1rem;
        }
      }
    }
  }

  .bannerSection {
    height: 300px;

    .button {
      bottom: auto;
      top: 5%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .followSection {
    padding: 0 1rem 1rem;
  }

  .productsSection {
    .sliderWrapper {
      .sliderDiv {
        .centerDiv {
          h5 {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .bannerSection2 {
    height: 400px;
  }
}

@media screen and (max-width: 499px) {
  .mainSlider {
    top: -60px;
    margin-bottom: -60px;
    height: 100vh;

    .sliderDiv {
      height: 100vh;
      min-height: 670px;

      .leftDiv {
        left: 1rem;
        bottom: 0;
        width: 50%;
        flex: 0 0 50%;
        max-width: 50%;

        .normal {
          display: none;
        }

        .big {
          display: none;
        }
      }

      .centertDiv {
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        flex: 0 0 100%;
        max-width: 100%;

        .normal {
          font-size: 16px;
          line-height: 160%;
          display: block;
        }

        .big {
          font-size: 24px;
          line-height: 160%;
          display: block;
        }
      }

      .rightDiv {
        right: 1rem;
        bottom: 0;
        width: 50%;
        flex: 0 0 50%;
        max-width: 50%;

        .normal {
          display: none;
        }

        .big {
          display: none;
        }
      }

      .normal {
        font-size: 14px;
        line-height: 160%;
        text-align: center;
      }

      .big {
        font-size: 20px;
        line-height: 160%;
        text-align: center;
      }
    }

    .socialDiv {
      display: none;
    }

    .buttonMobile {
      display: block;
      bottom: 160px;
      right: 50%;
      transform: translateX(50%);
    }

    .button {
      display: none;
      bottom: 80px;
      right: 50%;
      transform: translateX(50%);
    }
  }

  .secondSlider {
    margin: 0;

    .sliderDiv {
      .big {
        font-size: 24px;
        line-height: 160%;
      }
    }
  }

  .topBannerSection {
    min-height: 256px;
    background-image: url("../../assets/img/home/banners/top-banner.jpg");
    background-position: center center;
    background-size: cover;

    .bannerImg {
      display: none !important;
    }
  }

  .favoritesSection {
    margin: 0 0 1rem;
  }

  .bannerSection {
    margin: 0 0 2rem;
    min-height: 613px;
    background-image: url("../../assets/img/home/banners/home-swarovski-banner-comp-mobile.jpg");
    background-position: center center;
    background-size: cover;

    img {
      display: none !important;
    }

    .button {
      top: auto;
      bottom: 1rem;
    }
  }

  .followSection {
    margin: 0 0 2rem;
  }

  .productsSection {
    .sliderWrapper {
      padding: 3rem 3rem 8rem;

      .arrowLeft {
        left: 1rem;
      }

      .arrowRight {
        left: auto;
        right: 1rem;
      }

      .button {
        bottom: 2rem;
      }

      .sliderDiv {
        .centerDiv {
          h5 {
            font-size: 16px;
            line-height: 20px;
          }

          .imgDiv {
            padding: 0;
          }
        }
      }
    }
  }

  .bannerSection2 {
    min-height: 625px;
    background-image: url("../../assets/img/home/banners/home-aritsan-banner-comp-mobile.jpg");
    background-position: center center;
    background-size: cover;

    img {
      display: none !important;
    }

    .button {
      display: none;
    }
  }
}
