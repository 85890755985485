@import "../assets/scss/variables";

.button {
  outline: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
  padding: 1rem 1.5rem;
  border: 1px solid $black;
  border-radius: 5px;
  transition: all ease 0.3s;
  cursor: pointer;

  &:hover {
    background-color: $black;
    color: $white;
  }

  img {
    position: relative;
    top: -1px;
  }

  &.colorWhite {
    border-color: $white;
    color: $white;

    &:hover {
      border-color: $white;
      background-color: $white;
      color: $black;
    }
  }
}

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: transform 0.2s ease-in-out;
}
