@import "../../assets/scss/variables";

@keyframes progress-animation {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0;
  }
}

.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #efefef, $alpha: 0.9);
  backdrop-filter: blur(10px);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;

  .bar {
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 6px;
    overflow: hidden;
    background: linear-gradient(270deg, #f2994a 0%, #f24a4a 35.94%, #2c5aff 66.67%, #22bdff 100%);
    background-size: 300% 100%;
    animation: progress-animation 2s linear infinite;
  }
}

.wrapper {
  padding: 0;
}

.productInfoSection {
  .infoDivBg {
    background-color: #ffffff;

    .imagesDiv,
    .textDiv {
      padding: 66px 40px 66px 80px;
    }

    .imagesDiv {
      .selectedImage {
        background-color: #f2f2f4;
        border-radius: 14px;
        text-align: center;
        margin-bottom: 2rem;

        img {
          width: 100%;
          max-width: 370px;
        }
      }

      .allImages {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        cursor: pointer;
        margin: 0;

        .imageDiv {
          border-bottom: 1px solid #cacaca;
          padding-bottom: 1rem;

          .image {
            padding: 1rem;

            img {
              width: 100%;
            }
          }

          &.active {
            .image {
              background-color: #f2f2f4;
              border-radius: 8px;
            }

            border-bottom: 1px solid #979797;
          }
        }
      }
    }

    .textDiv {
      .device {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #bdbdbd;
        margin-bottom: 8px;
      }

      .title {
        font-weight: 500;
        font-size: 44px;
        line-height: 53px;
        color: #000000;
        margin-bottom: 32px;
        white-space: pre-line;
      }

      .colorsDiv {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 32px;
        overflow: visible;

        .color {
          width: 20px;
          height: 20px;
          background-color: $gray;
          border-radius: 20px;
          margin-right: 8px;
          position: relative;
          cursor: pointer;

          &.active {
            &::before {
              content: "";
              width: 28px;
              height: 28px;
              border-radius: 28px;
              background-color: inherit;
              position: absolute;
              border: 2px solid #bcbcbc;
              top: -4px;
              left: -4px;
            }
          }
        }
      }

      .description {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 32px;
        white-space: pre-line;
      }

      .note {
        font-size: 12px;
        line-height: 18px;
        opacity: 0.2;
      }

      .deviceVersion {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 3rem;

        > div {
          cursor: pointer;
          border-bottom: 1px solid transparent;
          margin-right: 2rem;

          &:hover {
            border-color: black;
          }
        }
      }
    }
  }
}

.bannersSection {
  .bannerDiv {
    width: 100%;
    background-color: $gray-4;

    img {
      width: 100%;
    }
  }
}

.postersSection {
  background: #f2f2f4;
  padding: 422px 0 0;
  position: relative;
  width: 100%;
  z-index: -1;
  margin-top: -390px;
  margin-bottom: 2rem;

  &.noBanners {
    padding: 390px 0 0;
  }

  .posterDiv {
    width: 100%;

    img {
      width: 100%;
    }
  }
}

.insideSection {
  margin-bottom: 3rem;
  position: relative;

  .insideDiv {
    width: 100%;
    margin-bottom: 2rem;

    img {
      width: 100%;
    }
  }

  .grayBg {
    background-color: #f2f2f4;
    width: 100%;
    height: 421px;
    position: absolute;
    z-index: -1;
    bottom: 124px;
    left: 0;
  }
}

.relatedProductsSection {
  position: relative;
  margin-bottom: 2rem;

  .sliderWrapper {
    position: relative;

    .arrowLeft,
    .arrowRight {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 2rem;
      display: none !important;
    }

    .arrowLeft {
      transform: translateY(-50%) rotate(180deg);
    }

    .arrowRight {
      left: auto;
      right: 2rem;
    }

    .sliderDiv {
      cursor: pointer;

      .centerDiv {
        padding: 0 2.5rem;

        h5 {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 128px;
          white-space: pre-line;
        }

        .imgDiv {
          padding: 2.5rem;
          position: relative;
          transition: all ease 0.3s;

          img {
            width: 100%;
            transition: all ease 0.3s;
          }

          .button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: none;
            transition: all ease 0.3s;

            img {
              width: auto;
              filter: blur(0);
              transition: all ease 0.3s;
            }
          }
        }
      }

      &:hover {
        .centerDiv {
          .imgDiv {
            img {
              opacity: 0.5;
              filter: blur(4px);
            }

            .button {
              display: block;

              img {
                width: auto;
                opacity: 1;
                filter: blur(0);
              }
            }
          }
        }
      }
    }
  }
}

.bottomBanner {
  position: relative;

  img {
    width: 100%;
  }

  .bottomBannerDiv {
    position: absolute;
    left: 100px;
    top: 66%;

    h1 {
      color: $white;
      max-width: 400px;
      margin-bottom: 48px;
    }

    img {
      width: auto;
    }
  }
}

.aboutSection {
  padding: 135px 0 0;
  margin-bottom: 64px;
  position: relative;
  overflow: hidden;

  .eBg {
    width: 100%;
    max-width: 770px;
    position: absolute;
    top: -180px;
    left: 15%;
    z-index: -1;
  }
}

.prevNextSection {
  margin-bottom: 4rem;

  .navigation {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 1.5rem;

    &.navigationNext {
      text-align: right;
    }
  }

  .categoryDiv {
    width: 100%;

    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1319px) {
  .postersSection {
    padding-top: 360px;
    margin-top: -338px;

    &.noBanners {
      padding-top: 338px;
    }
  }

  .bottomBanner {
    .bottomBannerDiv {
      left: 84px;
    }
  }

  .insideSection {
    img {
      max-height: 332px;
    }

    .grayBg {
      height: 365px;
      bottom: 111px;
    }
  }
}

@media screen and (max-width: 1199px) {
  .productInfoSection {
    .infoDivBg {
      .imagesDiv,
      .textDiv {
        padding: 32px;
      }
    }
  }

  .postersSection {
    padding-top: 310px;
    margin-top: -283px;

    &.noBanners {
      padding-top: 283px;
    }
  }

  .bottomBanner {
    .bottomBannerDiv {
      left: 74px;
    }
  }

  .insideSection {
    img {
      max-height: 273px;
    }

    .grayBg {
      height: 304px;
      bottom: 97px;
    }
  }
}

@media screen and (max-width: 991px) {
  .postersSection {
    padding-top: 242px;
    margin-top: -210px;

    &.noBanners {
      padding-top: 210px;
    }
  }

  .relatedProductsSection {
    h1 {
      font-size: 32px;
    }

    .sliderWrapper {
      .sliderDiv {
        .centerDiv {
          padding: 0 1rem;

          h5 {
            margin-bottom: 64px;
          }

          .imgDiv {
            padding: 1rem;
          }
        }
      }
    }
  }

  .bottomBanner {
    .bottomBannerDiv {
      left: 60px;
    }
  }

  .aboutSection {
    padding: 64px 0 0;

    .eBg {
      max-width: 500px;
      top: -80px;
    }
  }

  .insideSection {
    img {
      max-height: 195px;
    }

    .grayBg {
      height: 227px;
      bottom: 79px;
    }
  }
}

@media screen and (max-width: 767px) {
  .postersSection {
    padding-top: 188px;
    margin-top: -156px;

    &.noBanners {
      padding-top: 156px;
    }
  }

  .bottomBanner {
    .bottomBannerDiv {
      left: 45px;
    }
  }

  .aboutSection {
    .eBg {
      left: 5%;
    }
  }

  .insideSection {
    img {
      max-height: none;
    }

    .grayBg {
      height: calc(100% - 88px);
      bottom: 0;
      top: 88px;
    }
  }
}

@media screen and (max-width: 576px) {
  .productInfoSection {
    margin-top: 1rem;

    .textDiv,
    .imagesDiv {
      padding: 0 !important;
    }

    .imagesDiv {
      margin-bottom: 2rem;
    }

    .textDiv {
      text-align: center;

      p,
      h1 {
        text-align: left;
      }

      h1.title {
        font-size: 27px !important;
        line-height: 1.6 !important;
      }
    }

    .button {
      margin-bottom: 2rem;
    }

    .deviceVersion {
      margin-top: 1rem;
      justify-content: space-around !important;

      > div {
        margin-right: 0 !important;
      }
    }
  }

  .bannersSection {
    .bannerDiv {
      width: calc(100% + 2rem);
      margin: auto -1rem;

      img {
        width: 100%;
      }
    }
  }

  .postersSection {
    padding-top: 120px;
    margin-top: -88px;

    &.noBanners {
      padding-top: 88px;
    }
  }

  .relatedProductsSection {
    .sliderWrapper {
      padding: 0 2rem;

      .arrowLeft,
      .arrowRight {
        display: block !important;
      }

      .arrowLeft {
        left: 0rem;
      }

      .arrowRight {
        left: auto;
        right: 0rem;
      }
    }
  }

  .bottomBanner {
    .bottomBannerDiv {
      display: none;
      left: auto;
      right: 1.5rem;
      top: 30%;
    }
  }

  .aboutSection {
    .eBg {
      max-width: 300px;
      top: -80px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .prevNextSection {
    margin-bottom: 4rem;

    .navigation {
      font-size: 1rem;
      line-height: 1.6;
    }
  }
}
