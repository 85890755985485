@import "../assets/scss/variables";

.wrapper {
  border: 1px solid #e0e0e0;
  border-radius: 14px;
}

.header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e0e0e0;

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: black;
    margin: 0;
  }
}

.content {
  padding: 1rem 1.5rem;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    opacity: 0.5;
    cursor: pointer;
    transition: all ease 0.3s;

    &:hover {
      opacity: 1;
    }

    &.selected {
      opacity: 1;
    }

    &.color {
      opacity: 1;
    }

    .colorSpan {
      width: 1rem;
      height: 1rem;
      background-color: #fff;
      border-radius: 1rem;
      margin-right: 1rem;
      display: inline-block;
      position: relative;
      top: 4px;
      border: 1px solid #e9e9e9;
    }
  }
}
