.wrapper {
  text-align: center;
}

.image {
  width: 100%;
  max-width: 512px;
  margin-bottom: 2rem;
}

.button {
  display: inline-block;
}
