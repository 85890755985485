@import "../assets/scss/variables";

.button {
  outline: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: center;

  span {
    position: relative;
    display: block;
    cursor: pointer;
  }

  span::before,
  span::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    top: 50%;
    margin-top: -1px;
    background: $black;
  }

  span::before {
    left: -1.5px;
    background: $black;
  }
  span::after {
    right: 1.5px;
    transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  &:hover span::after {
    background: $black;
    width: calc(100% + 4px);
    transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  &:hover span::before {
    background: transparent;
    width: calc(100% + 4px);
    transition: 0s;
  }

  img {
    position: relative;
    left: -4px;
    transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  &:hover {
    img {
      left: -3px;
    }
  }

  &.white {
    span::before,
    span::after {
      content: "";
      position: absolute;
      width: 0%;
      height: 2px;
      top: 50%;
      margin-top: -1px;
      background: $white;
    }

    span::before {
      left: -2.5px;
      background: $white;
    }
    span::after {
      right: 2.5px;
      transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
    }

    &:hover span::after {
      background: $white;
      width: 100%;
      transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
    }

    &:hover span::before {
      background: transparent;
      width: 100%;
      transition: 0s;
    }
  }
}
