.rotateLeft {
  transform: rotate(180deg);
}

.rotateDown {
  transform: rotate(90deg);
}

img {
  display: inline-block !important;
}
