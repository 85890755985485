@import "../assets/scss/variables";

.wrapper {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 14px;
  padding: 24px;
  cursor: pointer;
  transition: all ease 0.3s;
  height: 100%;

  &:hover {
    border-color: #bdbdbd;
  }

  .infoDiv {
    .subtitle {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      opacity: 0.2;
    }

    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      min-height: 58px;
      white-space: pre-line;
    }
  }

  .productImgDiv {
    text-align: center;
    padding: 88px 0 80px;
    min-height: 424px;

    img {
      width: 100%;
      max-width: 256px;
    }
  }

  .bottomDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 20px;
    }

    .colorsDiv {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-flow: row wrap;
      max-width: 140px;

      .color {
        width: 20px;
        height: 20px;
        background-color: $gray;
        border-radius: 20px;
        margin-left: 8px;
        margin-top: 8px;
        position: relative;

        &.active {
          &::before {
            content: "";
            width: 28px;
            height: 28px;
            border-radius: 28px;
            background-color: #fff;
            position: absolute;
            border: 2px solid #bcbcbc;
            z-index: -1;
            top: -4px;
            left: -4px;
          }
        }
      }
    }

    .heart {
      color: #ff1b1b;
    }
  }

  &.wrapperList {
    position: relative;
    padding: 24px 24px 32px;

    .productImgDiv {
      padding: 0;
      margin-right: 1rem;
      min-height: auto;

      img {
        width: 256px;
      }
    }

    .infoDiv {
      .title {
        margin-bottom: 1rem;
      }

      .description {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $gray-3;
      }
    }

    .bottomDiv {
      position: absolute;
      bottom: 1rem;
      left: 296px;
      width: calc(100% - 48px - 256px);

      img {
        width: 20px;
      }

      .colorsDiv {
      }
    }
  }
}
