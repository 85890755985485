@import "../assets/scss/variables";

.imgDiv {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  position: relative;
  transition: all ease 0.3s;

  img {
    width: 100%;
  }

  .button {
    background-color: $black;
    color: $white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;

    img {
      width: auto;
    }
  }

  &:hover {
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba($color: $white, $alpha: 0.2);
      position: absolute;
      left: 0;
      right: 0;
    }

    .button {
      display: block;
    }
  }
}
